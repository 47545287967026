<!--设备列表-->
<template>
	<div class="page">
		<Tables :showSeq="false" ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
		 :handleSearch="true" @onSearch="onSearch" @clearSearch="clearSearch" @getList="getList">
			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
<!--				<el-select v-model="searchFormData.agent_id" v-show="agentList" placeholder="按代理商查询" clearable class="from-item-width from-item-split">-->
<!--					<el-option v-for="agent in agentList" :key="agent.agent_id" :label="agent.agent_name" :value="agent.agent_id"></el-option>-->
<!--				</el-select>-->
				<el-select
						v-model="searchFormData.agent_id"
						class="from-item-width from-item-split"
						filterable
						reserve-keyword
						clearable
						placeholder="按代理商查询"
						:remote-method="getAgentList"
						:loading="groupLoading">
					<el-option
							v-for="item in agentList"
							:key="item.agent_id"
							:label="item.agent_name"
							:value="item.agent_id">
					</el-option>
				</el-select>
				<el-form-item>
					<el-date-picker class="from-item-width" unlink-panels v-model="dates" type="daterange" clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="group_name" title="小区名称" align="center" />
			<vxe-table-column slot="table-item" field="electric" title="用电量(度)" align="center" />
			<vxe-table-column slot="table-item" field="all_num" title="订单总数" align="center" />
			<vxe-table-column slot="table-item" field="all_order_money" title="现金支付分成" align="center" />
			<vxe-table-column slot="table-item" field="all_recharge_money" title="充值支付分成" align="center" />
			<vxe-table-column slot="table-item" field="all_recharge_card_money" title="卡充值分成" align="center" />
			<vxe-table-column slot="table-item" field="cash_money" title="现金分成" align="center" />
			<vxe-table-column slot="table-item" field="cash_refund" title="现金退款" align="center" />
			<vxe-table-column slot="table-item" field="recharge_refund" title="充值退费金额" align="center" />
			<vxe-table-column slot="table-item" field="charge_refund" title="消费退费金额" align="center" />
			<vxe-table-column slot="table-item" field="all_money" title="累计分成总额" align="center" />
			<vxe-table-column slot="table-item" field="all_card_num" title="卡消费金额" align="center" />
			<vxe-table-column slot="table-item" field="card_reality_money" title="卡实际收入" align="center" />
<!--			<vxe-table-column slot="table-item" field="all_recharge_wx_money" title="微信充值订单金额" align="center" />-->
			<!--			<vxe-table-column slot="table-item" field="all_order_num" title="微信消费订单数量" align="center" />-->
			<!--			<vxe-table-column slot="table-item" field="all_recharge_money_num" title="微信充值订单数量" align="center" />-->

			<!--			<vxe-table-column slot="table-item" field="all_recharge_card_num" title="卡充值订单数量" align="center" />-->

			<vxe-table-column slot="table-item" field="all_order_member_money" title="钱包消费总额" align="center" />
			<vxe-table-column slot="table-item" field="all_order_short_money" title="现金消费总额" align="center" />

		</Tables>
	</div>
</template>

<script>
	import Tables from "@/components/tables";

	export default {
		name: "report-group-list",
		components: {
			Tables,
		},
		data() {
			return {
				// table信息
				tableName: "小区消费查询",
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {},
				dates: [],
				agentList: [],
				shareStatus: {
					10: {
						txt: '不结算',
						class: 'sd_spare2'
					},
					20: {
						txt: '未结算',
						class: 'sd_error',
					},
					30: {
						txt: '已结算',
						class: 'sd_spare'
					}
				}
			};
		},
		mounted() {
			this.init();
		},
		activated() {
			this.init();
			// this.$refs.xTable.refreshTable();
		},
		methods: {
			// 初始化请求
			init() {
				this.getAgentList();
			},
			// 获取代理商信息
			async getAgentList() {
				const params = {
					token: this.$store.state.user.token,
					status: 40,
					page: 1,
					size: 1000,
				};
				const res = await this.$api.Agent.GetAgentList(params, {});
				this.agentList = res.data;
			},
			// 获取列表
			async getList(currentPage, pageSize) {
				if (this.searchFormData.agent_id) {
					this.searchFormData.start_time = parseInt(this.dates[0].getTime() / 1000);
					this.searchFormData.end_time = parseInt(this.dates[1].getTime() / 1000);
					const params = {
						token: this.$store.state.user.token,
						page: currentPage,
						size: pageSize,
						form: JSON.stringify(this.searchFormData)
					};
					this.isLoading = true;
					const res = await this.$api.Report.GetGroupView(params);
					this.tableData = res; // res.data;
					this.totalPage = res.length;
					this.isLoading = false;
				} else {
					this.tableData = [];
					this.totalPage = 0;
				}
			},
			// 查询
			onSearch() {
				if (!this.searchFormData.agent_id) {
					if (!this.agentList){
						this.searchFormData.agent_id=99999999999999999999999999999999999;
					} else {
						this.$message.warning('请选择要查询的代理商')
						return;
					}
				}
				if (!this.dates || this.dates.length != 2) {
					this.$message.warning('请选择要查询的时间段')
					return;
				}
				this.getList();
			},
			// 重置查询
			clearSearch() {
				this.dates = [];
				this.searchFormData = {};
			}
		},
	};
</script>

<style lang="less" scoped>
	.from-item-width {
		width: 240px;
	}

	.from-item-split {
		margin-right: 20px;
	}

	.sd_spare {
		color: #008e4d;
	}

	.sd_spare2 {
		color: #0055ff;
	}

	.sd_error {
		color: #f56c6c;
	}

	.sd_total {
		color: #b28e2f;
	}

	.add-dialog-form {
		.select {
			width: 100%;
		}
	}
</style>
